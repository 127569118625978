@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.emptyState {
  @include font-primary-bold-SB5;
  align-items: center;
  color: $clay-50;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 $spacing-24;
  text-align: center;

  &.darkMode {
    color: $bone;
  }
}
