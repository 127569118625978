@use "~rivals/styles/variables" as *;

@use "~rivals/styles/typography" as *;
@import "~rivals/styles/mixins";

.listLink {
  @include font-primary-bold-SB3;
  @include article-block-wrapper;
  display: block;
}
