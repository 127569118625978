@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/components/shared/Widgets/mixins";

.titleSelect {
  @include widget-header-title-select;
}

.optionItem {
  @include widget-header-option-item;
}

.optionName {
  @include widget-header-option-name;
  margin-left: 6px;
}

.buttonClass {
  margin: 0 $spacing-8;
  width: calc(100% - #{$spacing-16});
}

.scheduleSection {
  padding: 0 $spacing-8 $spacing-12;

  .scheduleHeader {
    @include font-primary-bold-SB6;
    margin-bottom: $spacing-4;
    text-transform: uppercase;
  }
}

.records {
  display: flex;
  flex-direction: row;
  margin-left: $spacing-4;
  padding-top: 0;

  > *:first-child {
    margin-right: $spacing-24;
  }

  .record {
    order: 1;
    @include font-primary-body-B7;

    .winLoss {
      @include font-primary-medium-S5;
    }
  }
}

.finshedSection {
  background-color: transparent;
  box-shadow: inset 0 2px 4px rgba($abyss, 0.12);
  padding-top: $spacing-12;
}

.games > *:not(:last-child) {
  margin-bottom: $spacing-12;
}

.finshedSectionDarkMode {
  background-color: transparent;
  box-shadow: none;
}
