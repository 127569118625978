@import "~rivals/styles/mixins";
@import "~rivals/styles/variables";
@import "~rivals/styles/typography";
@import "~rivals/styles/colors";
@import "~rivals/styles/breakpoints";

@mixin defaultContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  @include font-primary-bold-SB5;
  @include defaultContainer;
  flex-direction: column;
  grid-area: dashboard;
  max-width: 100%;
  padding: 0 18px 0 $spacing-12; // Right side has 18px to accommodate for the button
  @include sizes-ending-with($screen-tablet-max) {
    padding: 0;
    width: 100%;
  }
}

.topContainer {
  @include defaultContainer;
  align-items: flex-end;
  column-gap: $spacing-12;
  padding-bottom: $spacing-12;
  @include sizes-ending-with($screen-tablet-max) {
    flex-direction: column;
  }
}

.rail {
  display: flex;
  flex-direction: column;

  &.left {
    flex: 1 1 100%;
    overflow-x: hidden;
    @include sizes-ending-with($screen-tablet-max) {
      overflow-x: unset;

      > * {
        margin-bottom: $spacing-12;
      }
    }
  }
}

.subNav {
  display: flex;
  justify-content: center;
  width: 100%;
  @include sizes-starting-with($screen-tablet-min) {
    justify-content: flex-end;
    margin-bottom: 0;
    padding-bottom: $spacing-12;
  }

  & > div {
    display: flex;
    justify-content: flex-end;
  }
}

.body {
  @include defaultContainer;
  @include sizes-ending-with($screen-tablet-max) {
    flex-direction: column;
  }
}

.headlineNewsFallback {
  @include font-primary-bold-SB4;
  align-items: center;
  color: $bone-50;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 100%;
  @include sizes-starting-with($screen-sm-desktop-min) {
    @include font-primary-bold-SB3;
    min-height: 646px;
  }

  .checkBackPrompt {
    order: 1;
    text-align: center;
  }
}

.teamSelectorContainer {
  @include sizes-ending-with($screen-tablet-max) {
    width: 100%;

    .dashboardTeamSelector {
      width: 100%;
    }
  }
}

.eventTickerContainer {
  overflow-x: hidden;
  width: 100%;
  @include sizes-ending-with($screen-tablet-max) {
    align-items: center;
    display: flex;
    flex: unset;
    height: 138px;
    justify-content: center;
    overflow-x: unset;
  }
}
