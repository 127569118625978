@import "~rivals/styles/colors";

.lightBoxRoot {
  :global {
    .ant-image-preview-operations,
    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right,
    .ant-image-preview-close {
      background: $bone;
    }

    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right,
    .ant-image-preview-close {
      &:hover {
        background: $bone-50;
      }
    }
  }
}
