@import "~rivals/styles/colors";
@import "~rivals/components/shared/Form/styles";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.input {
  @include input;

  &:read-only {
    cursor: default;
  }
}

.input.feedback {
  border-color: $rivals-red;
}

.input.infoPrompt {
  border-color: $curious-blue;
}

.inputPassword {
  // https://griosf.atlassian.net/browse/RVLS-3667
  // Remove Yahoo Sans so the password dots display correctly on iOS, and bump the
  // font size up 1px so they look as much like the Yahoo Sans dots as possible.
  font-family: "Helvetica Neue", arial, sans-serif;
  font-size: 14px;
}
