@import "~rivals/styles/typography";
@import "~rivals/styles/exports.module.scss";

.texturedBackground {
  background-blend-mode: multiply;
  background-color: $clay-50;
  background-size: 100% 100%;
}

.variant1 {
  background-image: url("/static/images/texture_1.webp");
}

.variant2 {
  background-image: url("/static/images/texture_2.webp");
}
