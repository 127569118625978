@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";

.wrapper {
  align-items: center;
  border-radius: 50px;
  color: $white;
  display: flex;
  justify-content: center;
  text-align: center;
  @include font-yahoo-h1-secondary;
  @include unselectable-text;

  &.defaultBackground {
    background-color: $gray50;
  }
  @include sizes-ending-with($screen-sm-mobile-max) {
    height: 50px;
    width: 50px;
  }
}
