@import "~rivals/styles/breakpoints";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.galleryContainer {
  border: 1px solid $gray90;
  margin: auto;
  padding: $spacing-12;
  @include article-block-wrapper;
  @include sizes-ending-with($screen-mobile-max) {
    max-width: 300px;
  }
  @include sizes-between($screen-lg-mobile-min, $screen-lg-mobile-max) {
    max-width: 460px;
  }
}
