@import "~rivals/styles/colors";

.darkBG,
.lightBG {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba($ebony-clay, 0) 0%,
      $ebony-clay 100%
    ),
    $gray30;
  border-radius: 50%;

  /* parent element MUST have display: block, this is OPTIONAL here bc it's the default behavior for <div> elements */
  display: block;

  padding: 10px;

  /* parent element MUST have position: relative, for proper rendering of image element when rendered with fill prop */
  position: relative;

  .logo {
    /*
     * DO NOT DELETE OR EDIT THE PADDING UNLESS NECESSARY
     * the reason is that fill property we set on next/image adds position: absolute; and that will not respect the parent element padding
     * unless we set the same padding on the child element _in this case it's the logo image from next/image_
     * reference: https://www.w3.org/TR/CSS21/visudet.html#containing-block-details
    */
    left: 0;
    padding: inherit;
    position: absolute;
    top: 0;
  }
}

.lightBG {
  background: $white;
}

.hasOverlayIcon {
  position: relative;
}

.overlayIcon {
  position: absolute;

  :first-child {
    height: 100%;
    position: inherit;
    width: 100%;
  }
}
