@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.container {
  width: 100%;

  :global {
    .ant-select-single.ant-select-lg {
      height: 48px;
    }

    .ant-select-selection-item a {
      padding: 0;
      pointer-events: none;
    }
  }
}

.teamSelector {
  width: 100%;
}

.teamOptionLink {
  @include font-primary-bold-SB6;
  align-items: center;
  color: $abyss;
  display: flex;
  flex-direction: row;
  gap: $spacing-12;
  padding: 5px $spacing-12 5px $spacing-16;

  &:hover {
    color: $abyss;
  }
}

.teamName {
  @include font-primary-body-B6;
}

.groupLabel {
  @include font-primary-medium-S5;
  @include set-vertical-padding($spacing-12);
  color: $abyss-90;
  padding-left: 17px;
}

.groupedOptions {
  :global(.ant-select-item.ant-select-item-option) {
    padding: 0;
  }
}
