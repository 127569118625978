@use "~rivals/styles/breakpoints" as *;

@use "~rivals/styles/colors" as *;

@use "~rivals/styles/typography" as *;

@import "~rivals/styles/variables";
@import "~rivals/styles/mixins";

.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
}

.figure {
  @include article-block-wrapper;
  display: table;
  flex: 1;
  width: fit-content;
}

.image {
  width: 100%;
}

.caption {
  @include font-yahoo-b5;
  caption-side: bottom;
  color: $gray50;
  display: table-caption;
  padding-top: 12px;
}

.customButton {
  width: 100%;
}
