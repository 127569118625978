@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/hide-elements.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.errorPage {
  @include font-primary-body-B4;
  color: $black;
  margin: 60px auto 0px;
  max-width: 580px;
  padding: $spacing-12;
  text-align: center;
  @include sizes-ending-with($screen-lg-mobile-max) {
    margin-top: 40px;
  }

  .football,
  .whistle {
    background-position: center;
    height: 150px;
    margin: 0 auto;
    width: 150px;
  }

  .football {
    background: url("/static/images/football.png") no-repeat;
    background-size: 150px 150px;
  }

  .whistle {
    background: url("/static/images/whistle.png") no-repeat;
    background-size: 150px 150px;
  }

  .errorText {
    @include font-primary-bold-SB1;
    font-weight: 700;
    margin: $spacing-24 0 $spacing-12;
  }

  .resetPrompt {
    @include font-primary-body-B4;
    margin-top: $spacing-36;

    .resetLink {
      color: $call-to-action-blue;

      &:hover {
        color: $link-hover;
        transition: color 0.3s;
      }
    }
  }
}
