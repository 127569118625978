@import "~rivals/components/shared/Form/styles";

.label {
  @include input-label;
}

.feedback {
  @include font-yahoo-b5;
  color: $rivals-red;
  margin-top: 4px;
  min-height: 16px;
  width: auto;

  [name="logInLink"] {
    color: $call-to-action-blue;
  }
}

.hint {
  @include font-yahoo-b5;
  color: $gray50;
  margin-top: 4px;
}

.infoPrompt {
  @include font-yahoo-b5;
  color: $curious-blue;
}
