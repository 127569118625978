@import "~rivals/styles/colors";
@import "~rivals/components/shared/Form/styles";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.input {
  @include input;
  height: 100px;
  padding: 8px 12px;
}

.input.feedbackBorder {
  border-color: $rivals-red;
}

.inputPassword {
  // https://griosf.atlassian.net/browse/RVLS-3667
  // Remove Yahoo Sans so the password dots display correctly on iOS, and bump the
  // font size up 1px so they look as much like the Yahoo Sans dots as possible.
  font-family: "Helvetica Neue", arial, sans-serif;
  font-size: 14px;
}

.feedback {
  @include font-yahoo-b5;
  color: $rivals-red;
  margin-top: 4px;
  min-height: 16px;
}

.hint {
  @include font-yahoo-b5;
  color: $gray50;
  margin-top: 4px;
}

.label {
  @include input-label;
}
