@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/variables.scss";

$screen-small-mobile-max: 400px;

.modalWrapper {
  :global {
    .ant-modal-header {
      padding: 8px;
    }

    .ant-modal-body {
      padding: 0 64px $spacing-24 64px;
      @include sizes-ending-with($screen-lg-mobile-max) {
        padding: 0 $spacing-24 $spacing-24;
      }
      @include sizes-ending-with($screen-small-mobile-max) {
        padding: 0 $spacing-8 $spacing-24;
      }
    }
  }
}
