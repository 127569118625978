@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.paywall {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  .bullets {
    @include font-primary-medium-S4;
    list-style: none;
    margin-bottom: $border-radius-28;

    .bulletRow {
      display: flex;
      flex-direction: row;
      @include sizes-ending-with($screen-lg-mobile-max) {
        align-items: start;
      }

      svg {
        flex-shrink: 0;
        margin-top: $spacing-12;
      }
    }

    .bullet {
      margin-left: $spacing-6;
      padding: $spacing-4;
    }
  }

  &.gallery {
    padding: 96px 0;
    @include sizes-ending-with($screen-lg-mobile-max) {
      padding: $spacing-36 0;
    }

    .bullets {
      color: $bone;
      margin-bottom: $spacing-64;
    }

    .bulletRow {
      @include sizes-ending-with($screen-lg-mobile-max) {
        align-items: center;

        svg {
          margin-top: 0;
        }
      }
    }

    .bullet {
      @include sizes-ending-with($screen-lg-mobile-max) {
        @include font-primary-bold-SB5;
      }
    }

    .logInButton {
      background-color: $bone;
      color: $abyss;
    }

    .rIconContainer {
      top: unset;
    }

    .subscribe {
      color: $bone;
      margin: $spacing-36 0;
      @include sizes-ending-with($screen-lg-mobile-max) {
        text-align: center;
      }
    }
  }
}

.subscribe {
  @include font-primary-bold-SB1;
  margin-bottom: $spacing-16;
  margin-top: $spacing-36;
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-primary-bold-SB2;
  }
}

.linkButton {
  @include font-primary-bold-SB4;
  align-items: center;
  color: $black;
  display: flex;
  height: 56px;
  justify-content: center;
  margin-bottom: $spacing-24;
  padding: $spacing-8 $spacing-36;
  text-align: center;
  text-decoration: none;
  width: 393px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    width: 317px;
  }
}

.goBig {
  background: linear-gradient(to bottom, transparent 20%, $black 100%);
}

.logInButton {
  border: 1px solid $abyss-10;
  color: #848484;
}

.summary {
  @include sizes-starting-with($screen-tablet-min) {
    @include font-primary-bold-SB2;
  }
}

.rIconContainer {
  position: relative;
}
