@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/hide-elements.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.thirdPartyConsentEmbed {
  display: block;
}

.blockedContentCard {
  @include font-yahoo-b4;
  align-items: center;
  border: 1px solid $gray85;
  border-radius: 8px;
  margin: 5px;
  padding: 12px;

  a {
    color: $call-to-action-blue;
    font-weight: inherit;
  }

  .loadedCard {
    display: flex;
    flex-direction: row;
  }
}

.blockedContentAlert {
  color: $gray90;
  width: 50px;
}

.blockedContentBody {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
