@use "~rivals/styles/mixins" as *;
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/variables";

.container {
  @include article-block-wrapper;
  display: flex;
  justify-content: center;
  max-width: $body-width;
  width: 100%;
  @include sizes-ending-with($screen-lg-mobile-max) {
    max-width: 100%;
  }
}
