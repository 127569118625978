@import "~rivals/styles/mixins";
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/typography";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.table {
  :global {
    .ant-table-content::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    .ant-table-tbody > .ant-table-row:hover > td {
      background: unset;
    }

    .ant-table-column-sorter {
      display: none; // hide so we can add custom sorting icons
    }
  }

  // Basic table styling

  :global(.ant-table-thead) > tr > th {
    background: $white;
    border-bottom: $border-85;
    color: $trout;
    padding: $spacing-12;
    text-align: center;
    text-transform: uppercase;
    @include font-yahoo-h5;
  }

  :global(.ant-table-tbody) {
    color: $trout;
    @include font-yahoo-b4;

    > tr {
      height: 60px;
      position: relative;

      > td {
        &:global(.ant-table-cell) {
          justify-content: center;
          padding: 0;
          position: unset;
          z-index: 2;
        }
      }
    }
  }

  &.emptyTable {
    :global {
      .ant-table-container {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    tbody > tr > td {
      background: $white;
    }
  }

  .darkRow {
    background-color: $rivals-gray;
  }
}
