@import "~rivals/styles/variables";

.videoPlayer {
  height: 100%;
  width: 100%;

  .videoPlayerOverlay {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  iframe {
    border-width: 0;
    height: 100%;
    width: 100%;
  }

  .aolVideoWrapper {
    align-items: center;
    display: flex;
  }

  .hudlVideoWrapper {
    height: auto;
  }
}
