.embedContainer {
  max-width: 625px;
  padding-bottom: 36%;
  position: relative;
  width: 100%;
}

.embed {
  border: 0;
  height: 100%;
  left: 0;
  max-width: 600;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
