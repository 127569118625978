@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/hide-elements.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

@mixin labelFont13 {
  @include font-yahoo-h4;
  font-weight: 700;
}

.shareMenu {
  position: relative;

  .shareIcon,
  .openShareIcon {
    background-color: $gray30;
    border-radius: 50%;
    cursor: pointer;
    height: 35px;
    padding: 0;
    position: relative;
    width: 35px;
    z-index: 10;

    i.shareIcon svg g {
      fill: $white;
    }

    .shareSvg {
      height: 35px;
      padding: 7px 7px 7px 4px;
      width: 35px;
    }

    .shareSvg svg g {
      fill: #bbbec1;
    }
  }

  .openShareIcon {
    background-color: $white;

    .shareSvg svg g {
      fill: $gray50;
    }
  }

  .shareIcon:hover {
    .shareSvg svg g {
      fill: $white;
    }
  }

  .shareIcon:active,
  .openShareIcon:active {
    .shareSvg svg g {
      fill: rgba(0, 0, 0, 0.9);
    }
  }

  .shareIconMenuClosed,
  .shareIconMenuProspectOpen,
  .shareIconMenuContentOpen {
    background-color: transparent;
    border-radius: 30px;
    height: 55px;
    overflow: hidden;
    padding-left: $spacing-12;
    position: absolute;
    right: calc(#{$spacing-12} * -1);
    top: -10px;
    transition: width 0.5s, background-color 0.5s;
    width: 50px;
    z-index: 9;

    .iconHolder {
      border-radius: 30px;
      display: flex;
      height: 100%;
      max-width: calc(100% - (25px + #{$spacing-12}));
      overflow: hidden;
      padding: 10px 0;

      .icon {
        cursor: pointer;
        height: 35px;
        margin-right: 12px;
        order: 1;
        padding: 0;
        width: 35px;

        .iconImage {
          height: 35px;
          width: 35px;
        }
      }
    }
  }

  .shareIconMenuProspectOpen {
    background-color: $black;
    width: 250px;
  }

  .shareIconMenuContentOpen {
    background-color: rgba($black, 0.9);
    width: 300px;
  }
}
