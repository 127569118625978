@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.gameCard {
  @include light-mode;
  box-shadow: 0px 1px 4px 0px rgba($abyss, 0.12);
  height: 121px;
  overflow: hidden;
  padding-bottom: $spacing-8;
  position: relative;

  .colorHeader {
    height: 4px;
    position: absolute;
    width: 100%;

    .homeTeamColorHeader {
      height: 100%;
      width: 50%;
    }
  }

  .statusHeader {
    @include font-primary-body-B5;
    display: flex;
    flex-direction: row;
    padding: $spacing-8 $spacing-16 0;

    .liveIndicator {
      background-color: $crimson;
      border-radius: 50%;
      height: 7px;
      margin: auto $spacing-4 auto 0;
      width: 7px;
    }
  }

  .bettingOdds {
    @include font-primary-body-B7;
    margin-top: -$spacing-8;
    text-align: center;
  }

  .gameStatistics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $spacing-8 $spacing-16;
  }
}

.darkMode {
  @include dark-mode;
  background-color: transparent;
  box-shadow: none;
}

.awayTeam {
  text-align: right;

  .teamBlock {
    .primary {
      flex-direction: row-reverse;
      justify-content: right;

      .icon {
        margin: 0 0 0 $spacing-16;
      }
    }
  }
}

.teamBlock {
  @include font-primary-body-B6;
  justify-content: left;

  .primary {
    display: flex;
    flex-direction: row;

    .statLine {
      line-height: 40px;
      order: 2;
      padding: 0 $spacing-16;
      position: relative;
    }
  }

  .secondary {
    @include font-primary-bold-SB5;
    margin-top: $spacing-4;
  }
}

.scoreLine {
  @include font-primary-bold-SB6;
}

.activeGame {
  margin-top: 3px;
  outline: 3px solid $pass;
}

.winner {
  bottom: 0;
  height: 100%;
  margin: auto 0;
  position: absolute;
  right: 0;
  top: 1px;
  width: 10px;
}

.awayWinner {
  left: 0;
  top: -2px;
  transform: rotate(180deg);
}
