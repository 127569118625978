@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";

.textBlock {
  @include font-primary-body-B4;
  word-break: break-word;

  p {
    @include article-block-wrapper;
  }

  a {
    color: $hydro;
    text-decoration: none;
    transition: color 300ms ease;

    &:hover {
      color: $hydro-30;
    }
  }
}
