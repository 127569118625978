@import "~rivals/styles/breakpoints";
@import "~rivals/styles/mixins";
@import "~rivals/styles/variables";

.container {
  @include article-block-wrapper;
  max-width: $body-width;
  @include sizes-ending-with($screen-lg-mobile-max) {
    max-width: 100%;
  }
}
