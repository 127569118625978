@use "~rivals/styles/typography" as *;

@use "~rivals/styles/variables" as *;

@use "~rivals/styles/breakpoints" as *;

.wrapper {
  border-bottom: 1px solid $sand-30;
  max-width: $homepage-main-content-width;
  overflow: hidden;
  width: 100%;
}

.contentModuleTitle {
  @include font-extended-headline-H3;
  margin-bottom: $spacing-12;
  text-transform: uppercase;
  @include sizes-ending-with($screen-tablet-max) {
    @include font-extended-headline-H4;
    margin-bottom: $spacing-8;
  }
}

.cardWrapper {
  &:not(:first-of-type) {
    margin-top: $spacing-16;
  }
  @include sizes-ending-with($screen-tablet-max) {
    &:first-of-type > .contentCard > div > p {
      // targets ContentCard.summary to hide it
      display: none;
    }
  }
}

.seeMolButton {
  margin-top: $spacing-24;
  outline: none;
  padding: 0;

  > span {
    // targets the button text wrapper
    margin-left: auto;
    margin-right: $spacing-6;
  }
}

.seeMore svg {
  // targets the button chevron icon
  transform: rotate(180deg);
}
