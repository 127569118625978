@import "~rivals/styles/breakpoints";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.shareIconsContainer {
  align-items: center;
  background: $white;
  border: 1px solid $eye-black-10;
  border-radius: 20px;
  display: flex;
  flex-direction: row;

  /* this property is only included so the element displays correctly in storybook */
  float: left;
  padding: $spacing-4 $spacing-4 $spacing-4 $spacing-12;
  @include sizes-ending-with($screen-tablet-min) {
    padding-left: 8px;
  }

  &.shadow {
    box-shadow: 0px 1px 4px 0px $eye-black-10;
  }
}

.collapsibleShareButtonsContainer {
  box-sizing: content-box;
  display: flex;
  gap: $spacing-16;
  overflow: hidden;
  padding: 0 $spacing-16;
  transition: all 250ms ease;
  @include sizes-ending-with($screen-tablet-min) {
    gap: $spacing-8;
    padding: 0 $spacing-8;
  }

  $icon-width: 30px;
  $number-of-icons: 4;
  $number-of-gaps: $number-of-icons - 1;
  width: $number-of-icons * $icon-width + $number-of-gaps * $spacing-16;
  @include sizes-ending-with($screen-tablet-min) {
    $icon-width: 23.3px;
    width: $number-of-icons * $icon-width + $number-of-gaps * $spacing-8;
  }
}

.collapsed {
  padding: 0 $spacing-4;
  width: 0;
}

.shareLabel {
  @include font-primary-bold-SB5;
}

.shareOptionIcon {
  $share-icon-default-height: 31px;
  $share-icon-default-width: 30px;
  $share-icon-small-height: 24px;

  display: flex;
  height: $share-icon-default-height;
  width: $share-icon-default-width;
  @include sizes-ending-with($screen-tablet-min) {
    height: $share-icon-small-height;
    // Decimal number, calculating by rule of three
    width: $share-icon-default-width * $share-icon-small-height /
      $share-icon-default-height;
  }

  img,
  svg {
    vertical-align: baseline;
  }
}
