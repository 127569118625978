@use "~rivals/styles/mixins" as *;

@use "~rivals/styles/exports.module.scss" as exports;

@mixin set-vertical-spacing($desktop-bs: $spacing-12, $touch-bs: $spacing-8) {
  margin-bottom: $desktop-bs;
  @include sizes-ending-with($screen-tablet-max) {
    margin-bottom: $touch-bs;
  }
}

@mixin set-card-width($max-width) {
  max-width: $max-width;
  width: 100%;
}

@mixin set-image-dimensions($width) {
  height: auto;
  max-width: $width;
  width: 100%;
}

@mixin common-content-card {
  @include set-card-width(700px);
  align-items: center;
  flex-direction: row;

  .image {
    @include set-image-dimensions(211px);
  }

  .detailsWrapper {
    padding: $spacing-12;
  }

  .title {
    @include font-primary-bold-SB3;
    line-height: 20px;
    @include sizes-ending-with($screen-mobile-max) {
      @include font-primary-bold-SB4;
    }
  }

  .summary {
    @include font-primary-body-B4;
  }

  .extraDetails .extraDetailsText {
    @include font-primary-body-B6;
  }
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    @include set-card-width(742px);
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include set-card-width(336px);

    .detailsWrapper {
      @include font-primary-body-B6;
      padding: $spacing-4 $spacing-8;
    }

    .extraDetails .extraDetailsText {
      @include font-primary-body-B7;
    }

    .title {
      @include font-primary-medium-S5;
      line-height: 1.2;
    }

    .summary {
      display: none;
    }
  }
  @include sizes-ending-with($screen-mobile-max) {
    .image {
      @include set-image-dimensions(120px);
    }
  }
}

.contentCard {
  @include unselectable-text;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .detailsWrapper {
    flex: 1;
    min-width: 0;
  }

  .title {
    @include set-vertical-spacing;
    color: $abyss;
  }

  .summary {
    @include set-vertical-spacing;
    @include font-primary-body-B5;
    color: $abyss;
  }

  .extraDetails {
    align-items: center;
    color: $abyss;
    display: flex;

    svg {
      margin-right: $spacing-8;
    }

    .extraDetailsText {
      @include font-primary-body-B7;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .liveAt {
      display: inline;
      text-transform: lowercase;
    }
  }
}

.highlightedContentCard {
  @include set-card-width(742px);

  .image {
    @include set-image-dimensions(742px);
  }

  .detailsWrapper {
    padding: $spacing-16 $spacing-8;
  }

  .title {
    @include font-primary-bold-SB2;
  }

  .summary {
    @include font-primary-body-B5;
  }

  .extraDetails .extraDetailsText {
    @include font-primary-body-B6;
  }
  @include sizes-ending-with($screen-tablet-max) {
    .detailsWrapper {
      padding: $spacing-12 $spacing-4;
    }

    .title {
      @include font-primary-bold-SB4;
      line-height: 20px;
    }

    .extraDetails .extraDetailsText {
      @include font-primary-body-B6;
    }
  }
}

.commonContentCard {
  @include common-content-card;
}

.smallCommonContentCard {
  @include common-content-card;
  @include sizes-starting-with($screen-tablet-min) {
    .summary {
      display: none;
    }
  }

  .image {
    @include set-image-dimensions(120px);
  }
}

.widgetContentCard {
  @include set-card-width(300px);
  align-items: center;
  flex-direction: row;

  .image {
    @include set-image-dimensions(120px);
  }

  .detailsWrapper {
    padding: $spacing-4 $spacing-8;
  }

  .title {
    @include set-vertical-spacing($spacing-8, $spacing-8);
    @include font-primary-medium-S6;
    line-height: 1.2;
  }

  .summary {
    display: none;
  }
}

.darkMode {
  .title,
  .summary,
  .extraDetails {
    color: $bone;
  }
}
