@import "~rivals/styles/breakpoints";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.component {
  color: $bone;
  display: flex;
  justify-content: center;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 490px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    max-width: 317px;
  }
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    max-width: 377px;
  }

  button {
    font-size: 17px;
  }
}

.homepage {
  padding: $spacing-24 0;
  @include sizes-ending-with($screen-tablet-max) {
    margin: 0;
    width: 100vw;
  }
}

%list {
  margin: $spacing-36 0;

  .listItem {
    p {
      @include font-primary-body-B3;
      margin-left: $spacing-24;
      @include sizes-between($screen-tablet-min, $screen-tablet-max) {
        @include font-primary-body-B4;
      }
    }

    margin-top: $spacing-24;

    &:first-child {
      margin-top: 0;
    }
  }

  .listItemHeading {
    display: flex;
    margin-bottom: $spacing-12;

    h3 {
      @include font-primary-bold-SB3;
      margin-bottom: 0;
      margin-left: $spacing-12;
      @include sizes-between($screen-tablet-min, $screen-tablet-max) {
        @include font-primary-bold-SB4;
      }
    }
  }
}

.list {
  @extend %list;
}

.listSm {
  @extend %list;
  margin: 32px 0;

  .listItem {
    margin-top: 32px;

    p {
      @include font-primary-body-B4;
    }
  }

  .listItemHeading {
    margin-bottom: $spacing-8;

    h3 {
      @include font-primary-bold-SB4;
    }
  }
}

%subtitle {
  @include font-extended-headline-H2;
  display: block;
  letter-spacing: -2;
  margin-bottom: 0;
  margin-top: $spacing-24;
  width: 100%;
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    @include font-extended-headline-H4;
    margin-top: $spacing-12;
  }
}

.subtitle {
  @extend %subtitle;
}

.subtitleSm {
  @extend %subtitle;
  @include font-extended-headline-H4;
  margin-top: $spacing-8;
}

%title {
  @include font-extended-headline-H1;
  display: block;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  width: 100%;
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    @include font-extended-headline-H2;
  }
}

.title {
  @extend %title;
}

.titleSm {
  @extend %title;
  @include font-extended-headline-H2;
}

.titleItem {
  @include sizes-ending-with($screen-lg-mobile-max) {
    display: block;
  }
}
