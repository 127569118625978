@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/exports.module.scss";

.noResultsWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 100px 0;

  > svg {
    height: 113px;
    width: 113px;
  }

  .mainMessage {
    color: $gray30;
    margin-top: 48px;
    @include font-yahoo-b3;
  }

  .descMessage {
    color: $gray30;
    margin-top: 6px;
    @include font-yahoo-b4;
  }
}
