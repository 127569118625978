@import "~rivals/styles/antd-select-overrides";
@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.selectWrapper {
  .errorStatus {
    &:global(.ant-select.ant-select-lg .ant-select-selector) {
      border-color: $rivals-red;
    }
  }

  .readOnly {
    color: $gray85 !important; //stylelint-disable-line declaration-no-important
    cursor: default;
    user-select: none;
  }

  .option {
    span {
      @include font-yahoo-b4;
    }
  }
  @mixin borderlessSelectStyles {
    :global {
      .ant-select .ant-select-selector,
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border: 0;
        box-shadow: none;
      }

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
        .ant-select-selector {
        padding-left: 0;
      }

      .ant-select-selection-item,
      .ant-select-single.ant-select-open .ant-select-selection-item {
        @include font-yahoo-h3-secondary;
        @include sizes-ending-with($screen-sm-mobile-max) {
          @include font-yahoo-h4;
        }
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          background: none;
        }

        .ant-select-arrow > div {
          display: none;
        }
      }

      .ant-select-arrow {
        right: 0px;
      }
    }
  }

  .selectWithRoundedBorders {
    :global {
      .ant-select {
        .ant-select-selector {
          border-radius: $border-radius-100;
        }

        .ant-select-selection-placeholder {
          color: $trout;
        }

        .ant-select-clear {
          font-size: 18px;
          height: fit-content;
          margin-top: 0;
          transform: translateY(-50%);
          width: fit-content;
        }
      }
    }
  }

  .selectWithoutBorders {
    @include borderlessSelectStyles;
  }

  .selectWithBlueBottomBorderAndValue {
    @include borderlessSelectStyles;
    border-bottom: 4px solid $call-to-action-blue;

    :global {
      .ant-select-selection-item,
      .ant-select-single.ant-select-open .ant-select-selection-item {
        color: $call-to-action-blue;
      }
    }
  }

  .selectDark {
    border: $clay-border;
    width: auto;
    @include sizes-ending-with($screen-lg-mobile-max) {
      width: 100%;
    }

    :global {
      @include antd-select-dark-mode-override($star, none, 0, $abyss);
    }

    &.isOpen,
    &.hasModifiedValue {
      :global {
        @include antd-select-dark-mode-override($bone-10, none, 0, $abyss);

        .ant-select-selection-placeholder {
          visibility: hidden;
        }
      }
    }
  }

  .selectDarkPopUp {
    background-color: $bone-10;

    :global
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $sand-30;
    }

    :global
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: $sand-30;
    }
  }
}
