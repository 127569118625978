@use "~rivals/styles/typography.scss" as *;

@use "~rivals/styles/variables.scss" as *;
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/mixins";

.tableContainer {
  @include article-block-wrapper;
  background-color: $bone-10;
  border: 1px solid $sand-30;
  padding: $spacing-16 $spacing-8 32px $spacing-8;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: $spacing-16 0;
}

.tableTitle {
  color: $abyss;
  padding: 0;
  @include font-primary-bold-SB3;
}

.tableCaption {
  color: $abyss;
  @include font-primary-bold-SB5;
}

.table {
  :global {
    .ant-table {
      background: transparent;
    }

    .ant-table-content > table {
      /* stylelint-disable-next-line declaration-no-important */
      width: auto !important;
    }

    .ant-table-title {
      color: $abyss;
      padding: $spacing-16 0;
      @include font-primary-bold-SB3;
    }

    .ant-table-thead > tr > th {
      background-color: $bone-10;
      border-bottom: 1px solid $sand-30;
      border-top: 1px solid $sand-30;
      min-width: 85px;
      padding: 7px $spacing-12;
      text-align: left;
      text-transform: none;
    }

    .ant-table-tbody > tr {
      height: auto;

      &:nth-child(odd) {
        background: $sand;
      }
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid $sand-30;
      border-right: 1px solid $sand-30;
      min-width: 85px;
      padding: 7px $spacing-12;
      @include font-primary-body-B4;

      &:nth-last-child(1) {
        border-right: 0;
      }
    }

    .ant-table-cell::before {
      display: none;
    }
  }
}

.cell {
  padding: 7px 12px;

  p {
    margin: 0;
  }
}
