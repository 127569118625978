@import "~rivals/styles/variables.scss";

.prospectEmbed {
  .iframeContainer {
    height: $athlete-embed-card-height;
    margin: 0 auto;
    max-width: $athlete-embed-card-width;
    position: relative;
    width: 100%;

    .spinnerWrapper {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .textBox {
    height: 100px;
    margin-top: $spacing-12;

    textarea {
      resize: none;
    }
  }

  .copyToClipboardButton {
    height: 32px;
    margin: $spacing-12 0 0 auto;
    order: 1;
    width: 100px;
  }
}
