@import "~rivals/styles/exports.module.scss";
@import "~rivals/styles/variables";

$svg-dimensions: 22px;

.wrapper {
  align-items: center;
  display: flex;

  .copyButton {
    height: fit-content;
    min-width: fit-content;
    padding: 0;
  }

  svg {
    height: $svg-dimensions;
    margin-right: $spacing-4 * 2;
    width: $svg-dimensions;
  }
}
