.carousel {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.container {
  display: flex;
}

.dragging {
  pointer-events: none;
}
