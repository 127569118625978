@import "~rivals/styles/colors";
@import "~rivals/styles/variables";
@import "~rivals/styles/typography";
@import "~rivals/styles/breakpoints";

$transition-duration: 1s;

.container {
  position: relative;
}

.overflowContainer {
  overflow: hidden;
}

.dashboardRow {
  display: grid;
  grid-column-gap: $spacing-12;
  grid-template-columns: 1fr $dashboard-widget-width;
  position: relative;
  transition: $transition-duration;

  &.twoColumn {
    grid-template-columns: 1fr ($dashboard-widget-width * 2 + $spacing-12);
    transition: $transition-duration;
  }

  &.fullWidth,
  &.twoColumn.fullWidth {
    grid-column-gap: 0;
    grid-template-columns: 1fr 0;
    transition: $transition-duration;
  }
}

.headlineNews {
  overflow-x: hidden;
  position: relative;
  transition: $transition-duration;
  width: 100%;
}

.linearGradient {
  background: linear-gradient(270deg, $black -17.5%, rgba(0, 0, 0, 0) 57.45%);
  height: calc(100% - $carousel-arrows-height);
  position: absolute;
  right: 0;
  width: 36px;
  z-index: 1;

  &.hidden {
    display: none;
  }
}

.buttonText {
  padding-right: $spacing-4;
  width: max-content;
}

.collapseButton {
  @include font-extended-headline-H5;
  align-items: center;
  background-color: $mud-90;
  border: none;
  color: $bone-50;
  cursor: pointer;
  display: flex;
  height: 200px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  right: $dashboard-widget-width + $spacing-4;
  top: calc(50% - 100px);
  transition: right $transition-duration, opacity 0.3s ease; // Added opacity to transition
  width: 24px;
  z-index: 2;

  &:hover {
    background-color: $mud-70;
    opacity: 1;
  }

  &.nationals {
    top: 40%;
  }

  &.collapseButtonForTwoColumn {
    right: calc($dashboard-widget-width * 2 + $spacing-12 + $spacing-4);
  }

  &.collapseButtonExpand {
    opacity: 1;
    right: -17px;
  }

  &.forceShowCollapseButton {
    opacity: 1;
  }
}

.buttonLabel {
  align-items: center;
  display: inline-flex;
  transform: rotate(-90deg);
}

.widgetGrid {
  column-gap: $spacing-12;
  display: grid;
  grid-template-columns: $dashboard-widget-width;
  grid-template-rows: repeat(2, $dashboard-widget-height);
  @include sizes-starting-with($screen-tablet-min) {
    height: 100%;
  }
  margin-top: -122px; // 162px (top events) - $ticker-dropdown-height
  row-gap: $spacing-12;
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    grid-template-columns: $dashboard-tablet-widget-width;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    grid-template-columns: $mobile-body-width;
  }

  &.twoColumn {
    grid-template-columns: repeat(2, $dashboard-widget-width);
    @include sizes-between($screen-tablet-min, $screen-tablet-max) {
      grid-template-columns: $dashboard-tablet-widget-width;
    }
    @include sizes-ending-with($screen-lg-mobile-max) {
      grid-template-columns: $mobile-body-width;
    }
  }

  &.nationals {
    // 162px (top events) - (40px + 50px + 12px) ($ticker-dropdown-height, team selector height, spacing)
    margin-top: -60px;
  }
}
