@import "~rivals/components/shared/Widgets/mixins";
@import "~rivals/styles/colors.scss";

$position-col-width: 40px;
$stars-col-width: 72px;
$athlete-column: calc(24px + $position-col-width + $stars-col-width);

ul.commitmentList {
  margin: 0;
  padding: 0;
}

.prospectRow,
.rowTitles {
  grid-template-columns: calc(100% - #{$athlete-column}) $position-col-width $stars-col-width;
}

.rowTitles {
  @include title-row;
  text-align: center;

  .athleteColumn {
    padding-left: $spacing-4;
    text-align: left;
  }
}
