@import "~rivals/components/shared/Widgets/mixins";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.prospectList {
  margin-bottom: 0;
  padding-inline-start: 0;
}

.avatar {
  align-items: center;
  display: flex;
}

.avatarText {
  display: block;
  padding-left: 12px;
}

.firstName,
.lastName {
  max-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lastName {
  @include font-primary-bold-SB5;
}

.logo {
  display: flex;
  justify-content: flex-end;
  margin-right: 6px;
}

.ordinality {
  width: 34px;
}

.position {
  @include font-primary-body-B7;
}

.prospectRow,
.titleRow {
  grid-template-columns: 34px 1fr 44px;
  padding-right: 4px;
}
