@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module";

.wrapper {
  @include article-block-wrapper;
  background-color: $bone;
  background-image: url("/static/icons/article/icn_quote.svg");
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  max-width: $body-width;
  min-width: $mobile-body-width;
}

.quote {
  @include font-yahoo-h2;
}

.author {
  @include font-yahoo-h3;
  display: flex;
  justify-content: flex-end;
  margin: $spacing-24;
}
