@import "~rivals/styles/breakpoints";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-12;
  margin-bottom: 10px;
  margin-top: 10px;
}

.metaContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;
}

.metaContent {
  @include font-primary-body-B5;
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
}

.title {
  @include font-primary-bold-SB1;
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-primary-bold-SB2;
  }
}

.shareEditRow {
  align-items: center;
  display: flex;
}

.shareModuleContainer {
  margin-left: $spacing-12;
}

.premiumBadge {
  align-items: center;
  background-color: $hoops;
  color: $white;
  display: flex;
  height: 20px;
  padding-left: 2px;
  width: 125px;
  @include font-primary-bold-SB7;
}

.premiumBadgeText {
  margin-left: -2px;
}
