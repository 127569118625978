@import "~rivals/components/shared/Widgets/mixins";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.singleColumn {
  @include center-title;
  @include font-primary-body-B6;
}

.athleteColumn {
  align-items: center;
  display: grid;
  grid-template-columns: 46px calc(100% - 46px);
  text-transform: none;

  .lastName {
    @include font-primary-bold-SB5;
    margin-top: -6px;
  }
}

.athleteStars {
  @include athlete-stars;
  display: flex;
  justify-content: space-between;
}
